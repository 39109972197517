import React, {ReactElement} from "react";
import * as styles from "./actualWork.module.scss";
import JSONData from "../../../content/actualWorkData.json";
import {Col, Row} from "react-bootstrap";

export default function ActualWork() {
    return <div className={styles.sectionWrapper}>
        <Row>
            <Col md={{offset: 1, span: 10}} lg={{offset: 2, span: 8}}>
                <div className={styles.header}>{JSONData.header}</div>
                <div className={styles.text}>{JSONData.text}</div>
            </Col>
        </Row>

        <Row className={styles.cardListWrapper}>
            {JSONData.cards.map((data: any, idx: number) => {
                const CardImage = require(`../../svg/pointers/${data.iconName}`).default as ReactElement;
                return <Col key={idx} className={styles.cardsWrapper} md={{span: 6}}
                            xl={{offset: 0, span: 4}}><ServiceCard header={data.title} text={data.text}
                                                                   icon={<CardImage/>}/></Col>
            })}</Row>

    </div>
}

function ServiceCard(props: ServiceCardComponentProps) {

    return <div className={styles.cardWrapper}>
        <div className={styles.icon}>
            {props.icon}
        </div>
        <div className={styles.cardHeader}>
            {props.header}
        </div>
        <div className={styles.cardText}>
            {props.text}
        </div>
    </div>
}

interface ServiceCardComponentProps {
    header: string,
    text: string,
    icon: ReactElement
}
