// extracted by mini-css-extract-plugin
export var header = "atOurCore-module--header--2a7da";
export var imageHideMobile = "atOurCore-module--imageHideMobile--fe897";
export var imageHigher = "atOurCore-module--imageHigher--9c97c";
export var images = "atOurCore-module--images--209b0";
export var quote = "atOurCore-module--quote--47e57";
export var quoteBy = "atOurCore-module--quoteBy--50a4e";
export var quoteByWrapper = "atOurCore-module--quoteByWrapper--68427";
export var quoteImage = "atOurCore-module--quoteImage--d6602";
export var quoteImageWrapper = "atOurCore-module--quoteImageWrapper--ca808";
export var quoteSectionWrapper = "atOurCore-module--quoteSectionWrapper--9083a";
export var quoteTitle = "atOurCore-module--quoteTitle--8563c";
export var quoteWrapper = "atOurCore-module--quoteWrapper--96278";
export var sectionTitle = "atOurCore-module--sectionTitle--4f83d";
export var text = "atOurCore-module--text--59307";
export var wrapper = "atOurCore-module--wrapper--dc1fb";