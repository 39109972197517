// extracted by mini-css-extract-plugin
export var cardWrapper = "diversityCard-module--cardWrapper--02076";
export var colWrapper = "diversityCard-module--colWrapper--8afa9";
export var header = "diversityCard-module--header--32358";
export var image = "diversityCard-module--image--04f27";
export var imageSecond = "diversityCard-module--imageSecond--49d39";
export var imageSecondWrapper = "diversityCard-module--imageSecondWrapper--ac520";
export var imageWrapper = "diversityCard-module--imageWrapper--ec70c";
export var secondHeader = "diversityCard-module--secondHeader--ba622";
export var secondPartWrapper = "diversityCard-module--secondPartWrapper--a7212";
export var statHeader = "diversityCard-module--statHeader--a72ed";
export var statText = "diversityCard-module--statText--b9917";
export var statWrapper = "diversityCard-module--statWrapper--12f20";
export var text = "diversityCard-module--text--edab7";