import React from "react";
import ServicesCardList from "../serviceCard/servicesCardList";
import {Col, Row} from "react-bootstrap";
import * as styles from "./diversityServices.module.scss"
import JSONData from "../../../content/diversityServicesData.json";


export default function DiversityServices() {

    return <div>
        <Row>
            <Col md={{offset: 1, span: 10}} lg={{offset: 2, span: 8}}>
                <div className={styles.sectionTitle}>{JSONData.sectionTitle}</div>
                <div className={styles.header}>{JSONData.header}</div>
                <div className={styles.text}>{JSONData.text}</div>
            </Col>
        </Row>
        <ServicesCardList/>
    </div>
}

