import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import React from "react";
import Layout from "../components/layout/layout";
import PageHero from "../components/pageHero/pageHero";
import JSONData from "../../content/diversityPageHeroData.json"
import {Col, Row} from "react-bootstrap";
import * as styles from "../styles/diversity.module.scss"
import DiversityCard from "../components/diversityCard/diversityCard";
import SocialActions from "../components/socialActions/socialActions";
import AtOurCore from "../components/atOurCore/atOurCore";
import ActualWork from "../components/actualWork/actualWork";
import DiversityServices from "../components/extendedServices/diversityServices";
import LatestNews from "../components/latestNews/latestNews";
import Cta from "../components/cta/cta";
import {Helmet} from "react-helmet";


export default function DiversityPage() {
    return <>
        <Helmet>
            <meta charSet="utf-8"/>
            <html lang="en"/>
            <title>Diversity - Recoded Recruitment</title>
        </Helmet>
        <Layout
            header={<Header transparent={true}/>}
            footer={<Footer/>}
        >
            <PageHero textMaxWidth={"810px"} text={JSONData.text} sectionTitle={JSONData.sectionTitle}
                      header={JSONData.header}/>
            <Row className={styles.explanationWrapper}>
                <Col className={styles.explanationHeader}>
                    {JSONData.explanationHeader}
                </Col>
                <Col className={styles.explanationText} xs={{offset: 1, span: 10}} md={{offset: 1, span: 5}}>
                    {JSONData.explanationText}
                </Col>
            </Row>
            <DiversityCard/>
            <AtOurCore/>
            <ActualWork/>
            <SocialActions/>
            <DiversityServices/>
            <div style={{ height: '140px' }}></div>
            {/**<LatestNews title={"All the latest news & insights"}/>
            <div style={{ height: '140px' }}></div>
            { /* <Cta/> */ }
        </Layout>
    </>
}
