import React from "react"
import JSONData from "../../../content/diversityCardData.json"
import * as styles from "./diversityCard.module.scss"
import {Col, Row} from "react-bootstrap";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import ListBulletBigSVG from "../../svg/listBulletBig.svg"

export default function DiversityCard() {

    const data = useStaticQuery(graphql`{
    image1: file(relativePath: {eq: "diversity/diversity-card-2.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: CONSTRAINED)
      }
    },
    image2: file(relativePath: {eq: "diversity/diversity-card-1.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: CONSTRAINED)
      }
    }    
  }
  `);

    return <div className={styles.cardWrapper}>
        <Row>
            <Col className={styles.imageWrapper} xl={{offset: 1, span: 4}}>
                <GatsbyImage className={styles.image} image={data.image1.childImageSharp.gatsbyImageData}
                             alt={"person"}/>
            </Col>
            <Col md={{offset: 1, span: 10}} xl={{offset: 1, span: 5}}>
                <div className={styles.header}>
                    {JSONData.headerFirst}
                </div>
                <div>
                    {JSONData.pointerList.map((data: any, idx: number) => {
                        return <Statistic key={idx} header={data.header} text={data.text}/>
                    })}
                </div>
            </Col>
        </Row>

        <Row className={styles.secondPartWrapper}>
            <Col className={styles.colWrapper} xs={{span: 12, order: 2}} lg={{offset: 1, span: 5, order: 1}}>
                <div className={styles.textWrapper}>
                    <div className={styles.secondHeader}>
                        {JSONData.headerSecond}
                    </div>
                    <div className={styles.text}>
                        {JSONData.text}
                    </div>
                </div>
            </Col>
            <Col className={styles.imageSecondWrapper} md={{offset: 1, span: 10, order: 1}}
                 lg={{offset: 1, span: 4, order: 2}}>
                <GatsbyImage className={styles.imageSecond} image={data.image2.childImageSharp.gatsbyImageData}
                             alt={"person"}/>
            </Col>
        </Row>

    </div>
}

function Statistic(props: StatisticProps) {
    return <div className={styles.statWrapper}>
        <div className={styles.statHeader}>
            <ListBulletBigSVG/>
            {props.header}
        </div>
        <div className={styles.statText}>
            {props.text}
        </div>

    </div>
}

interface StatisticProps {
    header: string,
    text: string
}
