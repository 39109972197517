import * as styles from "./atOurCore.module.scss"
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import {Col, Row} from "react-bootstrap";
import JSONData from "../../../content/atOurCoreData.json";

export default function AtOurCore() {

    const data = useStaticQuery(graphql`{
        image1: file(relativePath: {eq: "diversity/who-we-are/image-1.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        },
        image2: file(relativePath: {eq: "diversity/who-we-are/image-2.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        },
        image3: file(relativePath: {eq: "diversity/who-we-are/image-3.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        },
        image4: file(relativePath: {eq: "diversity/who-we-are/image-4.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        },
        founderImage: file(relativePath: {eq: "diversity/who-we-are/founder.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        }
    }`);

    return <div className={styles.wrapper}>

        <div className={styles.images}>
            <div className={styles.imageWrapper}>
                <GatsbyImage className={styles.image} alt={"person"}
                             image={data.image1.childImageSharp.gatsbyImageData}/>
            </div>
            <div className={styles.imageWrapper}>
                <GatsbyImage className={`${styles.image} ${styles.imageHigher}`} alt={"person"}
                             image={data.image2.childImageSharp.gatsbyImageData}/>
            </div>
            <div className={`${styles.imageWrapper}  ${styles.imageHideMobile}`}>
                <GatsbyImage className={styles.image} alt={"person"}
                             image={data.image3.childImageSharp.gatsbyImageData}/>
            </div>
        </div>

        <Row>
            <Col md={{offset: 1, span: 10}} lg={{offset: 2, span: 8}}>
                <div className={styles.sectionTitle}>{JSONData.sectionTitle}</div>
                <div className={styles.header}>{JSONData.header}</div>
                <div className={styles.text}>{JSONData.text}</div>
            </Col>

        </Row>

        <Row className={styles.quoteSectionWrapper}>
            <Col className={styles.quoteImageWrapper} xs={{span: 12}} sm={{offset: 1, span: 10}}
                 lg={{offset: 1, span: 5}} xl={{offset: 1, span: 4}}>
                <GatsbyImage className={styles.quoteImage} alt={"person"}
                             image={data.image4.childImageSharp.gatsbyImageData}/>
            </Col>
            <Col className={styles.quoteWrapper} xs={{span: 12}} sm={{offset: 1, span: 10}} lg={{offset: 1, span: 5}}
                 xl={{offset: 1, span: 5}}>
                <div className={styles.quoteTitle}>{JSONData.quoteTitle}</div>
                <div className={styles.quote}>{JSONData.quote}</div>
                <div className={styles.quoteByWrapper}>
                    <GatsbyImage alt={"founder"} image={data.founderImage.childImageSharp.gatsbyImageData}/>
                    <div className={styles.quoteBy}>{JSONData.quoteBy}</div>
                </div>
            </Col>
        </Row>

    </div>
}
